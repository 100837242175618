body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex {
	display: flex;
}
.flex_just_betw{
	display: flex;
	justify-content: space-between;
}
.flex_just_around{
	display: flex;
	justify-content: space-around;
}
.flex_just_cen{
	display: flex;
	justify-content: center;
}
.flex_just_end{
	display: flex;
	justify-content: flex-end;
}
.flex_wrap{
	flex-wrap: wrap
}
.flex_cen{
	align-items: center;
}

.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.ml10 {
  margin-left: 10px;
}
.ml20 {
  margin-left: 20px;
}
.ml30 {
  margin-left: 30px;
}
.mr10 {
  margin-right: 10px;
}
.mr20 {
  margin-right: 20px;
}
.mr30 {
  margin-right: 30px;
}
.m10 {
  margin: 10px;
}
.m20 {
  margin: 20px;
}
.m30 {
  margin: 30px;
}
.pt10 {
  padding-top: 10px;
}
.pt20 {
  padding-top: 20px;
}
.pt30 {
  padding-top: 30px;
}
.pr10 {
  padding-right: 10px;
}
.pr20 {
  padding-right: 20px;
}
.pr30 {
  padding-right: 30px;
}
.pb10 {
  padding-bottom: 10px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb30 {
  padding-bottom: 30px;
}
.pl10 {
  padding-left: 10px;
}
.pl20 {
  padding-left: 20px;
}
.pl30 {
  padding-left: 30px;
}
.p10 {
  padding: 10px;
}
.p20 {
  padding: 20px;
}
.p30 {
  padding: 30px;
}
.tx_c{
  text-align: center;
}
.tx_l{
  text-align: left;
}
.width300{
  width: 300px;
}
.width100{
  width: 100px;
}
.width130{
  width: 130px;
}
.titles{
  width: 100%;
  text-align: left;
  font-size: 16px;
  color: #333333;
  margin-bottom:10px ;
}
.table-operation a{
  margin-right: 10px;
}
.searchRol{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.loading{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}
.menu-main{
  width: 100%;
  background-color: #fff;
  padding: 20px;
}
.bgf{
  background-color: #fff !important;
}