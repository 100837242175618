.tagContainer{
    width: 100%;
    height: 50px;
    border-bottom:1px solid rgb(213, 227, 252) ;
    border-top:1px solid rgb(213, 227, 252) ;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: rgb(246, 248, 253);
}
.edit-tag{
    height: 48px !important;
    padding: 0px 20px !important;
    display: flex !important;
    align-items: center !important;
    border-left:none !important ;
    border-top:none !important ;
    border-bottom:none !important ;
    margin: 0px !important;
}
