#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.logo{
  height: 64px;
  line-height: 64px;
  color: #fff;
  text-align: center;
}
.spaceBetween{
  justify-content: space-between;
}
.otherHandle i{
  margin-right:20px ;
  cursor: pointer;
  font-style: normal;
}
.otherHandle i:last-child{color: red;}
